import { 
    checkUserSubscription, 
    subscribeToPushNotifications
} from '../pushNotifications/subscribeToPushNotifications';

export const handlePushNotificationSubscription = async () => {

  try {
    const isSubscribed = await checkUserSubscription();

    const permission = await Notification.requestPermission();
    if (permission === 'granted' && !isSubscribed) {
      await subscribeToPushNotifications();
    }
  } catch (error) {
    console.error('Failed to handle push notification subscription:', error);
  }
};