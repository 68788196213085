import { useEffect, useState } from "react";
import { DistanceProps } from "../types/Props";

export const useUserLocation = () => {
  const [userLatitude, setUserLatitude] = useState<number | null>(null);
  const [userLongitude, setUserLongitude] = useState<number | null>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLatitude(position.coords.latitude);
        setUserLongitude(position.coords.longitude);
      },
      (error) => {
        console.warn("Error code: " + error.code + ", message: " + error.message);
      });
    }
  }, []);

  return { userLatitude, userLongitude };
}

export function calculateDistance({ latitude, longitude, userLatitude, userLongitude }: DistanceProps) {
  if (userLatitude == null || userLongitude == null) {
    return null;
  }
  const R = 6371; // Earth radius in km
  const dLat = (userLatitude - latitude) * (Math.PI / 180);
  const dLon = (userLongitude - longitude) * (Math.PI / 180);
  const a =
      0.5 - Math.cos(dLat) / 2 +
      Math.cos(latitude * (Math.PI / 180)) * Math.cos(userLatitude * (Math.PI / 180)) *
      (1 - Math.cos(dLon)) / 2;

  return R * 2 * Math.asin(Math.sqrt(a));
}
