import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteProps } from '../types/Props';

const PrivateRoute: React.FC<RouteProps> = ({ element, isLoggedIn }) => {
  return isLoggedIn ? element : <Navigate to="/install" />;
};

const PrivateRoutePWA: React.FC<RouteProps> = ({ isLoggedIn, element }) => {
  return isLoggedIn ? element : <Navigate to="/login" />;
};

const PublicRoute: React.FC<RouteProps> = ({ isLoggedIn, element }) => {
  return isLoggedIn ? <Navigate to="/" /> : element;
};

export { PrivateRoute, PrivateRoutePWA, PublicRoute };