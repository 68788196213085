import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from '../contexts/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDownload, 
  faExclamationCircle, 
  faCompass,
  faArrowUpFromBracket,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/fontawesome-free-regular';

const Install: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  const { translations } = useLanguage();

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handler);
    window.addEventListener('appinstalled', () => {
      setIsInstalled(true);
      setIsDownloadReady(true);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
      window.removeEventListener('appinstalled', () => {});
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      try {
        await deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
          setIsInstalled(true);
          setIsDownloadReady(true);
        }
        setDeferredPrompt(null);
      } catch (error) {
        console.error('Failed to prompt for install:', error);
      }
    }
  };

  return (
    <div className="install-container">
      <div className="card install-card">
        <div className="card-header">
          <img src='/mainlogo.png' className='card-header-logo' alt='Install Page Logo'></img>
        </div>
        <p><strong>{translations.installpage.welcome}</strong></p>
        <p>{translations.installpage.description}</p>
        <p className="install-info-text"><FontAwesomeIcon icon={faDownload} className="listIcon"/> {translations.installpage.installinfo1}</p>
        <p className="install-info-text"><FontAwesomeIcon icon={faExclamationCircle} className="listIcon"/> {translations.installpage.installinfo2}</p>
        {!isInstalled && !isIos && deferredPrompt && (
          <button className="big-button install" onClick={handleInstallClick}>
            <FontAwesomeIcon icon={faDownload} className="listIcon"/>
            {translations.installpage.install}
          </button>
        )}
        {isDownloadReady && (
          <div className="install-ready">
            <div className="install-checkmark-wrapper">
              <svg className="install-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="install-checkmark-circle" cx="26" cy="26" r="25" fill="#4CAF50" />
                <path className="install-checkmark-check" fill="none" d="M14 27l7 7 16-16" />
              </svg>
            </div>
            <p className="install-ready-text">{translations.installpage.installready}</p>
            <p className="install-ready-subtext">{translations.installpage.findit}</p>
          </div>
        )}
        {isIos && (
          <div className="card ios-info">
            <h3>{translations.installpage.installios}</h3>
            <ul>
              <li>
                <strong>{translations.installpage.iosinfo1}</strong>
                <FontAwesomeIcon icon={faCompass} className="listIcon"/>
              </li>
              <li>
                <strong>{translations.installpage.iosinfo2}</strong>
                <FontAwesomeIcon icon={faArrowUpFromBracket} className="listIcon"/><br/>
              </li>
              <li>
                <strong>{translations.installpage.iosinfo3}</strong>
                <FontAwesomeIcon icon={faPlusSquare} className="listIcon"/>"
              </li>
              <li>
                <strong>{translations.installpage.iosinfo4}</strong>
                <FontAwesomeIcon icon={faCheck} className="listIcon"/>
              </li>
            </ul>
          </div>
        )}
        <span className="pswreg"><Link to="/login">{translations.installpage.skipInstall}</Link></span>
      </div>
    </div>
  );
}

export default Install;