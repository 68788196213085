import React, { useState, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PrivateRoute, PrivateRoutePWA, PublicRoute } from './components/ReactRoutes';
import { validateTokenAndRefresh } from './utils/authUtils';
import Install from './components/Install';
import Main from './components/Main';
import Login from './components/Login'
import Register from './components/Register'
import ResetPassword from './components/ResetPassword';
import Info from './components/Info';
import PrivacyStatement from './components/PrivacyStatement';
import NotFound from './components/NotFound';
import './styles/Main.css';
import './styles/Auth.css';
import './styles/Install.css';
import './styles/Modal.css';
import './styles/Loader.css';
import './styles/RequestList.css';
import './styles/Map.css';
import './styles/Card.css';
import './styles/Buttons.css';
import './styles/Checkbox.css';
import './styles/StatusCircle.css';
import './styles/PullToRefresh.css';
import './styles/Info.css';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  // Check if user is logged in and refresh token on page load
  useEffect(() => {
    validateTokenAndRefresh(setIsLoggedIn);
  }, []);

  // Check if app is installed on page load
  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || 
      (window.navigator as any).standalone === true;

    setIsPWA(isStandalone); 
  }, []);

  // Prevent scrolling when app is installed
  useEffect(() => {
    const applyNoScroll = () => {
      if (isPWA) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    };

    applyNoScroll();
    window.addEventListener('resize', applyNoScroll);

    return () => {
      window.removeEventListener('resize', applyNoScroll);
      document.body.classList.remove('no-scroll');
    };
  }, [isPWA]);

  return (
    <Router>
      <Routes>
      {isPWA ? (
          <>
            <Route path="/login" element={<PublicRoute isLoggedIn={isLoggedIn} element={<Login setIsLoggedIn={setIsLoggedIn} />} />} />
            <Route path="/" element={<PrivateRoutePWA isLoggedIn={isLoggedIn} element={<Main />} />} />
            <Route path="/install" element={<Navigate to="/login" />} />
            <Route path="/info" element={<PrivateRoutePWA isLoggedIn={isLoggedIn} element={<Info />} />} />
          </>
        ) : (
          <>
            <Route path="/install" element={<PublicRoute isLoggedIn={isLoggedIn} element={<Install />} />} />
            <Route path="/login" element={<PublicRoute isLoggedIn={isLoggedIn} element={<Login setIsLoggedIn={setIsLoggedIn} />} />} />
            <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<Main />} />} />
            <Route path="/info" element={<PrivateRoute isLoggedIn={isLoggedIn} element={<Info />} />} />
          </>
        )}
        <Route path="/register" element={<PublicRoute isLoggedIn={isLoggedIn} element={<Register setIsLoggedIn={setIsLoggedIn}/>} />} />
        <Route path="/resetpassword" element={<PublicRoute isLoggedIn={isLoggedIn} element={<ResetPassword />} />} />
        <Route path="/privacystatement" element={<PrivacyStatement />} />
        <Route path="*" element={<NotFound source='url'/>} />
      </Routes>
    </Router>
  );
}

export default App;