import { refreshToken } from './authUtils';

// API request function with token handling and refresh
const apiRequest = async (url: string, options: RequestInit = {}) => {
  try {
    const token = localStorage.getItem('token');

    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 403) {
      await refreshToken();

      const newToken = localStorage.getItem('token');
      const retryResponse = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${newToken}`,
        },
      });

      return retryResponse;
    }

    return response;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};

export default apiRequest;