import React, { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { APIProvider, Map, AdvancedMarker, Pin, useMap } from '@vis.gl/react-google-maps';
import { GoogleMapProps } from '../types/Props';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapsId = process.env.REACT_APP_GOOGLE_MAPS_ID;

const MapWithMarkers: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude }) => {
  const { translations } = useLanguage();
  const map = useMap();

  useEffect(() => {
    if (map && userLatitude != null && userLongitude != null) {
      const bounds = new window.google.maps.LatLngBounds();
      const requestLocation = new window.google.maps.LatLng(latitude, longitude);
      const userLocation = new window.google.maps.LatLng(userLatitude, userLongitude);

      bounds.extend(requestLocation);
      bounds.extend(userLocation);

      map.fitBounds(bounds);
      
    }
  }, [map, latitude, longitude, userLatitude, userLongitude]);

  if (!mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <Map
      defaultZoom={10}
      defaultCenter={{ lat: latitude, lng: longitude }}
      mapId={mapsId}
      className='map'
      streetViewControl={false}
    >
      <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
        <Pin background={'#ff5454'} glyphColor={'#000'} borderColor={'#000'} />
      </AdvancedMarker>
      {userLatitude != null && userLongitude != null && (
        <AdvancedMarker position={{ lat: userLatitude, lng: userLongitude }}>
          <Pin background={'#00aeff'} glyphColor={'#000'} borderColor={'#000'} />
        </AdvancedMarker>
      )}
    </Map>
  );
}

const GoogleMap: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude }) => {
  const { translations } = useLanguage();

  if (!apiKey) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <MapWithMarkers
            latitude={latitude}
            longitude={longitude}
            userLatitude={userLatitude}
            userLongitude={userLongitude}
          />
        </div>
      </APIProvider>
      <div className="markercontainer">
        <div className="marker-item">
          <span className="marker-color" style={{ backgroundColor: '#ff5454' }}></span>
          <span>{translations.requestlocation}</span>
        </div>
        {userLatitude != null && userLongitude != null && (
          <div className="marker-item">
            <span className="marker-color" style={{ backgroundColor: '#00aeff' }}></span>
            <span>{translations.ownlocation}</span>
          </div>
        )}
      </div>
    </div>
  );
}

const OwnRequestMap: React.FC<GoogleMapProps> = ({ latitude, longitude }) => {
  const { translations } = useLanguage();

  if (!apiKey || !mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <Map
            defaultZoom={14}
            defaultCenter={{ lat: latitude, lng: longitude }}
            mapId={mapsId}
            className='map'
            streetViewControl={false}
          >
            <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
              <Pin background={'#ff5454'} glyphColor={'#000'} borderColor={'#000'} />
            </AdvancedMarker>
          </Map>
        </div>
      </APIProvider>
    </div>
  );
}

export { GoogleMap, OwnRequestMap };