import React, { useState } from "react";
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from "../contexts/UserContext";
import { ModalProps } from "../types/Props";
import { useUserLocation } from "../utils/locationUtils";
import { isValidInput, isValidLength } from "../utils/validateInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faShare, faXmark } from '@fortawesome/free-solid-svg-icons';
import apiRequest from '../utils/api';
import Loader from './Loader';

const NewRequestModal: React.FC<ModalProps> = ({ onClose }) => {
  const { translations } = useLanguage();
  const { user } = useUser();
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [isLocationVisible, setIsLocationVisible] = useState(true);
  const { userLatitude, userLongitude } = useUserLocation();
  const [error, setError] = useState('');
  const [descError, setDescError] = useState('');
  const [locError, setLocError] = useState('');
  const [loading, setLoading] = useState(false);
  const maxLength = 500;
  const descriptionPattern = /^[a-zA-ZäöÄÖ0-9\s.,!?:-]*$/;

  if (!user) return <Loader />;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) return;

    setLoading(true);
    setError('');
    setLocError('');
    setDescError('');

    // If location is visible, but user's location is not available, get it from the database
    let latitude = userLatitude;
    let longitude = userLongitude;

    if (isLocationVisible && (latitude === null || longitude === null)) {
      try {
        const response = await apiRequest(`/api/user/location`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          latitude = data.latitude;
          longitude = data.longitude;
        } else {
          setError(translations.errors.genericerror);
          setLoading(false);
          return;
        }
      } catch (error) {
        setError(translations.errors.genericerror);
        setLoading(false);
        return;
      }
    }

    // If location is visible, but user's location is still not available, show an error
    if (isLocationVisible && (latitude === null || longitude === null)) {
      setLocError(translations.errors.locationfailed);
      setLoading(false);
      return;
    };

    if (!isValidLength(description, 1, maxLength)) {
      setDescError(translations.errors.invaliddesclength);
      setLoading(false);
      return;
    };

    if (!isValidInput(description, descriptionPattern)) {
      setDescError(translations.errors.invalidcharacters);
      setLoading(false);
      return;
    };

    try {
      const response = await apiRequest('/api/requests', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
         },
        body: JSON.stringify({ 
          category, 
          description, 
          latitude: isLocationVisible ? latitude : null, 
          longitude: isLocationVisible ? longitude : null,
        }),
      });

      if (!response.ok) {
        const data = await response.json();
        if (data.message === 'You have reached the daily request limit.') {
          setError(translations.errors.dailylimitreached);
        } else if (data.message === 'You have reached the limit of 2 active requests.') {
          setError(translations.errors.activelimitreached);
        } else {
          setError(data.message);
        }
      } else {
        window.location.reload();
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
    setLoading(false);
  };
  
  return (
    <div className="modal-container">
      <div className="modal-content-full">
        <div className="top-bar-modal">
          <h2>{translations.newrequest}</h2>
          <button className='modal-close' onClick={onClose}>X</button>
        </div>
        <div className="modal-content">
          <form className='modal-form' onSubmit={handleSubmit}>
            <p className="intertitle">{translations.category}</p>
            <div className="select-wrapper">
              <select 
                name="category" 
                className="dropdown" 
                onChange={(e) => setCategory(e.target.value)}
                value={category}
                required
              >
                <option value="" disabled>{translations.choosecategory}</option>
                <option value="bicycle_maintenance">{translations.bicycle_maintenance}</option>
                <option value="food_and_hydration">{translations.food_and_hydration}</option>
                <option value="transportation_need">{translations.transportation_need}</option>
                <option value="other">{translations.other}</option>
              </select>
            </div>
            <p className="intertitle">{translations.location}</p>
            <label className="checkbox-container">
              <input 
                type="checkbox" 
                value="location" 
                checked={isLocationVisible}
                onChange={(e) => setIsLocationVisible(e.target.checked)}
              />
              <div className="checkmark"></div>
              <small>{translations.showlocation}</small>
            </label>
            <p className="intertitle">{translations.phone}</p>
            <label>
              <FontAwesomeIcon icon={faPhone} />
              <input className="text-input phone-disabled" type="text" name="number" value={user.phone} disabled></input>
            </label>
            <p className="intertitle">{translations.description}</p>
            <div className="card">
              <textarea 
                name="description" 
                rows={4} 
                cols={40} 
                placeholder={translations.description} 
                maxLength={maxLength}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className="char-counter">
                {description.length}/{maxLength} {translations.characters}
              </div>
              {descError && <div className="error">{descError}</div>}
            </div>
            {error && <div className="error">{error}</div>}
            {locError && <div className="error">{locError}</div>}
            <button 
              type="submit" 
              className={`big-button send-request ${loading ? 'disabled' : ''}`}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faShare}/> {loading ? translations.loading : translations.submit}
            </button>
            <button type="button" className="big-button cancel" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewRequestModal;