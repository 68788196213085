import { RefObject } from 'react';

// Handle the verification code input change
export const handleVerificationCodeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  verificationCode: string,
  setVerificationCode: (code: string) => void,
  inputRefs: RefObject<(HTMLInputElement | null)[]>
) => {
  const value = e.target.value;
  if (/^\d$/.test(value)) {
    const newCode = verificationCode.split('');
    newCode[index] = value;
    setVerificationCode(newCode.join(''));

    // Move focus to the next input box
    if (index < 5 && inputRefs.current && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  }
};

// Handle verification code paste event
export const handlePaste = (
  e: React.ClipboardEvent<HTMLInputElement>,
  setVerificationCode: (code: string) => void,
  inputRefs: React.RefObject<(HTMLInputElement | null)[]>
) => {
  e.preventDefault();
  const paste = e.clipboardData.getData('text');
  if (/^\d{6}$/.test(paste)) {
    const newCode = paste.split('');
    setVerificationCode(paste);
    if (inputRefs.current) {
      newCode.forEach((digit, index) => {
        if (inputRefs.current && inputRefs.current[index]) {
          inputRefs.current[index]!.value = digit;
        }
      });
      // Move focus to the next empty input field
      const nextInput = inputRefs.current.find((input, index) => input && index >= newCode.length);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }
};