import apiRequest from "../utils/api";

export const viewRequest = async (request_id: number): Promise<void> => {
  try {
    const response = await apiRequest(`/api/requests/${request_id}/add-view`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Error adding a view to request:', error);
  }
};