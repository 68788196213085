import React, { useEffect, useState } from "react";
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from "../contexts/UserContext";
import User from '../types/User';
import { ModalProps } from "../types/Props";
import { isValidInput, isValidLength } from "../utils/validateInput";
import { useUserLocation } from '../utils/locationUtils';
import { checkUserSubscription } from '../pushNotifications/subscribeToPushNotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, 
  faPhone, 
  faMap, 
  faRightFromBracket, 
  faPenToSquare, 
  faFloppyDisk, 
  faXmark, 
  faTrash, 
  faLocationDot,
  faBell,
  faLanguage,
  faChevronRight,
  faChevronDown,
  faSignature,
  faCircleQuestion,
  faArrowUpRightFromSquare,
  faCheck,
  faLock,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import Loader from './Loader';
import apiRequest from "../utils/api";
import { Link } from "react-router-dom";

const SettingsModal: React.FC<ModalProps> = ({ onClose }) => {
  const { language, setLanguage, translations } = useLanguage();
  const { user, setUser } = useUser();
  const [originalUser, setOriginalUser] = useState<User>(user as User);
  const [isAccountEditMode, setIsAccountEditMode] = useState(false);
  const [isRadiusEditMode, setIsRadiusEditMode] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRadiusInfo, setShowRadiusInfo] = useState(false);
  const usernamePattern = /^[a-zA-ZäöÄÖ0-9_]{3,16}$/;
  const radiusPattern = /^[0-9]*$/;
  const [openSlider, setOpenSlider] = useState<string | null>(null);
  const {userLatitude, userLongitude } = useUserLocation();
  const [notificationStatus, setNotificationStatus] = useState({
    permissionGranted: false,
    endpointMatches: false,
  });

  useEffect(() => {
    if (!user) return;
    const checkNotificationStatus = async () => {
      const permissionGranted = Notification.permission === 'granted';
      const endpointMatches = await checkUserSubscription();

      setNotificationStatus({
        permissionGranted,
        endpointMatches,
      });
    };

    checkNotificationStatus();
  }, [user]);

  if (!user) return <Loader />;

  const toggleAccountEditMode = () => {
    setIsAccountEditMode(!isAccountEditMode);
    setSuccess('');
    setError('');
  };

  const toggleRadiusEditMode = () => {
    setIsRadiusEditMode(!isRadiusEditMode);
    setSuccess('');
    setError('');
  };

  const toggleSlider = (slider: string) => {
    setOpenSlider(openSlider === slider ? null : slider);
    handleCancel();
    setSuccess('');
    setError('');
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowPasswordChange = (toggle: boolean) => {
    setShowChangePassword(toggle);
    setOldPassword('');
    setNewPassword('');
    setPasswordError('');
  };

  const handleSubmitUsername = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidLength(user.user_name, 3, 16)) {
      setError(translations.errors.invalidusernamelength);
      return;
    };

    if (!isValidInput(user.user_name, usernamePattern)) {
      setError(translations.errors.invalidusernamechars);
      return;
    };

    try {
      const response = await apiRequest(`/api/user/username`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          user_name: user.user_name
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message); 
      } else {
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
        setOriginalUser(data.user);
        setIsAccountEditMode(false);
        setError('');
        setSuccess(translations.successes.settingsupdated);
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  };

  const handleSubmitRadius = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isValidLength(user.radius.toString(), 1, 3)) {
      setError(translations.errors.invalidradiuslength);
      return;
    };

    if (!isValidInput(user.radius.toString(), radiusPattern)) {
      setError(translations.errors.invalidradiuschars);
      return;
    };

    try {
      const response = await apiRequest(`/api/user/radius`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          radius: user.radius
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
      } else {
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
        setOriginalUser(data.user);
        setIsRadiusEditMode(false);
        setError('');
        setSuccess(translations.successes.settingsupdated);
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  };

  const handleSubmitNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword.length < 5) {
      setPasswordError(translations.errors.passwordtooshort);
      return;
    }

    try {
      const response = await apiRequest(`/api/user/password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          oldPassword: oldPassword,
          newPassword: newPassword
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message === 'Incorrect password') {
          setPasswordError(translations.errors.invalidpassword);
        } else {
          setPasswordError(data.message);
        }
      } else {
        setNewPassword('');
        setShowChangePassword(false);
        setPasswordError('');
        setSuccess(translations.successes.settingsupdated);
      }
    } catch (error) {
      setPasswordError(translations.errors.genericerror);
    }
  };

  const handleCancel = () => {
    setUser(originalUser);
    setIsAccountEditMode(false);
    setIsRadiusEditMode(false);
    setError('');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    window.location.reload();
  };

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiRequest(`/api/user`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
        window.location.reload();
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  };

  return (
    <div className="modal-container">
      <div className="modal-content-full">
        <div className="top-bar-modal">
          <h2>{translations.settingspage.settings}</h2>
          <button className='modal-close' onClick={onClose}>X</button>
        </div>
        <div className="modal-content">
          <ul>
            <li className="card list-item">
              <div className="list-header" onClick={() => toggleSlider('account')}>
                <FontAwesomeIcon icon={faUser} className="icon-left"/> {translations.settingspage.account}
                <FontAwesomeIcon icon={openSlider === 'account' ? faChevronDown : faChevronRight} className="icon-right"/>
              </div>
              <div className={`settings-slider ${openSlider === 'account' ? 'show' : ''}`}>
                <label>
                  <div className="spacer2"><FontAwesomeIcon icon={faSignature}/> {translations.username}:</div>
                  <input
                    className={`text-input ${isAccountEditMode ? 'active-edit' : 'inactive-edit'}`}
                    type="text"
                    name="usernamefield"
                    value={user.user_name}
                    disabled={!isAccountEditMode}
                    onChange={(e) => setUser({ ...user, user_name: e.target.value })}
                  />
                </label>
                { isAccountEditMode ? (
                  <>
                    <button
                      type="button"
                      className="big-button settings-save"
                      onClick={handleSubmitUsername}
                    >
                      <FontAwesomeIcon icon={faFloppyDisk}/> {translations.save}
                    </button>
                    <button
                      type="button"
                      className="big-button settings-cancel"
                      onClick={handleCancel}
                    >
                      <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="big-button settings-edit"
                    onClick={toggleAccountEditMode}
                  >
                    <FontAwesomeIcon icon={faPenToSquare}/> {translations.edit}
                  </button>
                )}
                <label>
                  <div className="spacer2"><FontAwesomeIcon icon={faPhone}/> {translations.phone}:</div>
                  <input 
                    className="text-input settings-phone inactive-edit"
                    type="text" 
                    name="phonefield" 
                    value={user.phone} 
                    disabled
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  />
                </label>
                {success && <div className="success">{success}</div>}
                {error && <div className="error">{error}</div>}<br />
                <button type="button" className="big-button password-change" onClick={() => toggleShowPasswordChange(true)}>
                  <FontAwesomeIcon icon={faLock}/> {translations.changepassword}
                </button><br />
                <button type="button" className="big-button account-delete" onClick={() => setShowDeleteConfirmation(true)}>
                  <FontAwesomeIcon icon={faTrash}/> {translations.settingspage.deleteaccount}
                </button>
              </div>
            </li>
            <li className="card list-item">
              <div className="list-header" onClick={() => toggleSlider('radius')}>
                <FontAwesomeIcon icon={faLocationDot} className="icon-left"/> {translations.settingspage.radiusandlocation}
                <FontAwesomeIcon icon={openSlider === 'radius' ? faChevronDown : faChevronRight} className="icon-right"/>
              </div>
              <div className={`settings-slider ${openSlider === 'radius' ? 'show' : ''}`}>
                <label>
                  <div className="radius-container">
                    <FontAwesomeIcon icon={faMap} className="listIcon"/> {translations.radius}: 
                    <div className="radius-info" onClick={() => setShowRadiusInfo(!showRadiusInfo)}>
                      {translations.whatisradius}
                    </div>
                  </div>
                  <div className={`radius-info-popup ${showRadiusInfo ? 'show' : ''}`}>
                    <p>{translations.radiusinfosettings}</p>
                  </div>
                  <div className="radius-input-container">
                    <input 
                      className={`text-input radius ${isRadiusEditMode ? 'active-edit' : 'inactive-edit'}`}
                      type="number" 
                      value={user.radius || ''}
                      disabled={!isRadiusEditMode}
                      onChange={(e) => setUser({ ...user, radius: parseInt(e.target.value) })}
                    />
                    <span className="radius-unit">{translations.km}</span>
                    { isRadiusEditMode ? (
                      <>
                        <button
                          type="button"
                          className="big-button settings-save"
                          onClick={handleSubmitRadius}
                        >
                          <FontAwesomeIcon icon={faFloppyDisk}/> {translations.save}
                        </button>
                        <button
                          type="button"
                          className="big-button settings-cancel"
                          onClick={handleCancel}
                        >
                          <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="big-button settings-edit"
                        onClick={toggleRadiusEditMode}
                      >
                        <FontAwesomeIcon icon={faPenToSquare}/> {translations.edit}
                      </button>
                    )}
                  </div>
                  {success && <div className="success">{success}</div>}
                  {error && <div className="error">{error}</div>}
                  <div>
                    {userLatitude && userLongitude ? (
                      <div>
                        <span className="text-spacer">
                         <FontAwesomeIcon icon={faLocationDot} className="icon-left"/> {translations.settingspage.ownlocationinfo}
                        </span>
                        <span className="success">
                          {translations.settingspage.inuse}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className="text-spacer">
                         <FontAwesomeIcon icon={faLocationDot} className="icon-left"/> {translations.settingspage.ownlocationinfo}
                        </span>
                        <span className="error">
                          {translations.settingspage.notinuse}
                        </span><br />
                        {translations.settingspage.ownlocationinfo2}
                      </div>
                    )}  
                  </div>
                </label>
              </div>
            </li>
            <li className="card list-item">
              <div className="list-header" onClick={() => toggleSlider('notifications')}>
                <FontAwesomeIcon icon={faBell} className="icon-left"/> {translations.settingspage.notifications}
                <FontAwesomeIcon icon={openSlider === 'notifications' ? faChevronDown : faChevronRight} className="icon-right"/>
              </div>
              <div className={`settings-slider ${openSlider === 'notifications' ? 'show' : ''}`}>
                {notificationStatus.permissionGranted && notificationStatus.endpointMatches ? (
                  <div>
                    <span className="text-spacer">
                      {translations.settingspage.notifications}
                    </span>
                    <span className="success">
                      {translations.settingspage.on}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="text-spacer">
                      {translations.settingspage.notifications}
                    </span>
                    <span className="error">
                      {translations.settingspage.off}
                    </span><br />
                    {translations.settingspage.notificationsinfo}
                  </div>
                )}
              </div>
            </li>
            <li className="card list-item">
              <div className="list-header" onClick={() => toggleSlider('language')}>
                <FontAwesomeIcon icon={faLanguage} className="icon-left"/> {translations.settingspage.language}
                <FontAwesomeIcon icon={openSlider === 'language' ? faChevronDown : faChevronRight} className="icon-right"/>
              </div>
              <div className={`settings-slider ${openSlider === 'language' ? 'show' : ''}`}>
                <div className="select-wrapper spacer3">
                  <select 
                    name="language" 
                    className="dropdown"
                    value={language} 
                    onChange={(e) => setLanguage(e.target.value)} 
                  >
                    <option value="english">English</option>
                    <option value="finnish">Suomi</option>
                    <option value="swedish">Svenska</option>
                  </select>
                </div>
              </div>
            </li>
              <li className="card list-item">
                <Link to="/info" className="list-header infopage-link">
                  <FontAwesomeIcon icon={faCircleQuestion} className="icon-left"/> {translations.settingspage.info}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon-right"/>
                </Link>
              </li>
            <li className="card list-item logout" onClick={() => setShowLogoutConfirmation(true)}>
              <div className="list-header">
              <FontAwesomeIcon icon={faRightFromBracket} className="icon-left"/> {translations.settingspage.logout}
              </div>
            </li>
          </ul>
          { showLogoutConfirmation &&
          <div className="confirmation-overlay">
            <div className="card confirm-card">
              <p>{translations.settingspage.confirmlogout}</p>
              <div className="confirmation-buttons">
                <button className="big-button confirmation-cancel grey" onClick={() => setShowLogoutConfirmation(false)}>
                  <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                </button>
                <button className="big-button confirmation-confirm warning" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faRightFromBracket}/> {translations.settingspage.logout}
                </button>
              </div>
            </div>
          </div>
          }
          { showDeleteConfirmation &&
            <div className="confirmation-overlay">
              <div className="card confirm-card">
                <p>{translations.settingspage.confirmaccountdelete}</p>
                <div className="confirmation-buttons">
                  <button className="big-button confirmation-cancel grey" onClick={() => setShowDeleteConfirmation(false)}>
                  <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                  </button>
                  <button className="big-button confirmation-confirm warning" onClick={handleDelete}>
                  <FontAwesomeIcon icon={faTrash}/> {translations.delete}
                  </button>
                </div>
              </div>
            </div>
          }
          { showChangePassword &&
            <div className="confirmation-overlay">
              <div className="card confirm-card new-password">
                <label>
                {translations.settingspage.currentpassword}:
                  <div className="input-container-password">
                    <input
                      type={showOldPassword ? 'text' : 'password'}
                      className="auth-input"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder={translations.settingspage.currentpassword}
                      required
                    />
                    <FontAwesomeIcon
                      icon={showOldPassword ? faEyeSlash : faEye}
                      className="password-icon"
                      onClick={toggleOldPasswordVisibility}
                    />
                  </div>
                </label>
                <label>
                {translations.newpassword}: <span className="password-info">{translations.min5chars}</span>
                  <div className="input-container-password">
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      className="auth-input"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder={translations.newpassword}
                      required
                    />
                    <FontAwesomeIcon
                      icon={showNewPassword ? faEyeSlash : faEye}
                      className="password-icon"
                      onClick={toggleNewPasswordVisibility}
                    />
                  </div>
                </label>
                {passwordError && <div className="error">{passwordError}</div>}
                <div className="confirmation-buttons">
                  <button className="big-button confirmation-cancel-red" onClick={() => toggleShowPasswordChange(false)}>
                    <FontAwesomeIcon icon={faXmark}/> {translations.cancel}
                  </button>
                  <button className="big-button confirmation-confirm" onClick={handleSubmitNewPassword}>
                    <FontAwesomeIcon icon={faCheck}/> {translations.changepassword}
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;