import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDI_xUUB_xG-0hIy4mRyhYDQNlTkyatCi4",
  authDomain: "roadservice-9479f.firebaseapp.com",
  projectId: "roadservice-9479f",
  storageBucket: "roadservice-9479f.appspot.com",
  messagingSenderId: "34307179438",
  appId: "1:34307179438:web:89f52cf7491148e37c3e3f",
  measurementId: "G-XMG0WZ4Q8G"
};

// Initialize Firebase
initializeApp(firebaseConfig);