import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const PrivacyStatement: React.FC = () => {
  const { translations } = useLanguage();
  const navigate = useNavigate();
  
  return (
    <div className="container">
      <div className="top-bar-info">
          <h2>{translations.privacystatement.title}</h2>
          <button className='page-close' onClick={() => navigate(-1)}>X</button>
      </div>
      <div className="top-bar-info-sides"></div>
      <div className="modal-content privacystatement-content">
        <h4>{translations.privacystatement.registerholder}</h4>
        <p>{translations.privacystatement.registername}</p>
        <p>pyorailijantiepalvelu@gmail.com</p>
        <h4>{translations.privacystatement.content}</h4>
        {translations.privacystatement.contentinfo}
        <ul className='privacystatement-list'>
          <li>
            {translations.privacystatement.contentlist1}
          </li>
          <li>
            {translations.privacystatement.contentlist2}
          </li>
          <li>
            {translations.privacystatement.contentlist3}
          </li>
          <li>
            {translations.privacystatement.contentlist4}
          </li>
        </ul>
        <h4>{translations.privacystatement.registersafety}</h4>
        <p>{translations.privacystatement.registersafetyinfo1}</p>
        <p>{translations.privacystatement.registersafetyinfo2}</p>
        <p>{translations.privacystatement.registersafetyinfo3}</p>
        <p>{translations.privacystatement.registersafetyinfo4}</p>
      </div>
    </div>
  );
};

export default PrivacyStatement;