import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChevronDown, 
  faChevronRight,
  faQuestion,
  faPaperPlane,
  faUserShield,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';

const InfoPage: React.FC = () => {
  const { translations } = useLanguage();
  const [openSlider, setOpenSlider] = useState<string | null>(null);
  const [openFAQSlider, setOpenFAQSlider] = useState<string | null>(null);
  const navigate = useNavigate();

  const toggleSlider = (slider: string) => {
    setOpenSlider(openSlider === slider ? null : slider);
  };

  const toggleFAQSlider = (slider: string) => {
    setOpenFAQSlider(openFAQSlider === slider ? null : slider);
  };

  const copyEmail = () => {
    navigator.clipboard.writeText("pyorailijantiepalvelu@gmail.com");
  }

  return (
    <div className="container">
      <div className="top-bar-info">
        <h2>{translations.infopage.info}</h2>
        <button className='page-close' onClick={() => navigate(-1)}>X</button>
      </div>
      <div className='top-bar-info-sides'></div>
      <div className="modal-content">
        <ul>
          <li className="card list-item">
            <div className="list-header" onClick={() => toggleSlider('faq')}>
              <FontAwesomeIcon icon={faQuestion} className="icon-left"/> {translations.infopage.faq}
              <FontAwesomeIcon icon={openSlider === 'faq' ? faChevronDown : faChevronRight} className="icon-right"/>
            </div>
            <div className={`settings-slider ${openSlider === 'faq' ? 'show' : ''}`}>
              <ul>
                <li className="card list-item">
                  <div className="list-header" onClick={() => toggleFAQSlider('q1')}>
                    <strong>{translations.infopage.q1}</strong>
                    <FontAwesomeIcon icon={openFAQSlider === 'q1' ? faChevronDown : faChevronRight} className="icon-right"/>
                  </div>
                  <div className={`settings-slider ${openFAQSlider === 'q1' ? 'show' : ''}`}>
                    {translations.infopage.a1}
                  </div>
                </li>
                <li className="card list-item">
                  <div className="list-header" onClick={() => toggleFAQSlider('q2')}>
                    <strong>{translations.infopage.q2}</strong>
                    <FontAwesomeIcon icon={openFAQSlider === 'q2' ? faChevronDown : faChevronRight} className="icon-right"/>
                  </div>
                  <div className={`settings-slider ${openFAQSlider === 'q2' ? 'show' : ''}`}>
                    {translations.infopage.a2}
                  </div>
                </li>
                <li className="card list-item">
                  <div className="list-header" onClick={() => toggleFAQSlider('q3')}>
                    <strong>{translations.infopage.q3}</strong>
                    <FontAwesomeIcon icon={openFAQSlider === 'q3' ? faChevronDown : faChevronRight} className="icon-right"/>
                  </div>
                  <div className={`settings-slider ${openFAQSlider === 'q3' ? 'show' : ''}`}>
                    {translations.infopage.a3}
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="card list-item">
            <div className="list-header" onClick={() => toggleSlider('question')}>
              <FontAwesomeIcon icon={faPaperPlane} className="icon-left"/> {translations.infopage.sendquestion}
              <FontAwesomeIcon icon={openSlider === 'question' ? faChevronDown : faChevronRight} className="icon-right"/>
            </div>
            <div className={`settings-slider ${openSlider === 'question' ? 'show' : ''}`}>
              <div>
                <span className='email'>pyorailijantiepalvelu@gmail.com</span>
                <button className='copybutton' onClick={copyEmail}>{translations.infopage.copy}</button>
              </div>
            </div>
          </li>
          <li className="card list-item">
            <Link to="/privacystatement" className="list-header infopage-link">
              <FontAwesomeIcon icon={faUserShield} className="icon-left"/> {translations.infopage.privacystatement}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon-right"/>
            </Link>
          </li>
        </ul>       
      </div>
    </div>
  );
};

export default InfoPage;