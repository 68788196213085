import { jwtDecode } from 'jwt-decode';
import apiRequest from './api';

const refreshToken = async () => {
  try {
    const response = await fetch('/api/user/refresh-token', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    const { accessToken } = data;

    localStorage.setItem('token', accessToken);

    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

const validateTokenAndRefresh = async (setIsLoggedIn: (value: boolean) => void) => {
  const token = localStorage.getItem('token');

  if (!token) {
    setIsLoggedIn(false);
    return;
  }

  let decoded: any;
  try {
    decoded = jwtDecode(token);
  } catch (error) {
    console.error('Invalid token:', error);
    setIsLoggedIn(false);
    return;
  }

  const isTokenExpired = decoded.exp * 1000 < Date.now();

  if (isTokenExpired) {
    try {
      const newToken = await refreshToken();
      if (newToken) {
        setIsLoggedIn(true);
        return;
      } else {
        setIsLoggedIn(false);
        return;
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      setIsLoggedIn(false);
      return;
    }
  }

  try {
    const response = await apiRequest('/api/user/protected-route');

    if (response.ok) {
      setIsLoggedIn(true);
    } else if (response.status === 401) {
      const newToken = await refreshToken();
      if (newToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
  } catch (error) {
    setIsLoggedIn(false);
  }
};

export { refreshToken, validateTokenAndRefresh };