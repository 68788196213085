import { useLanguage } from "../contexts/LanguageContext";
import { NotFoundProps } from "../types/Props";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';

const NotFound: React.FC<NotFoundProps> = ({ source }) => {
  const { translations } = useLanguage();
  const description = source === 'url' 
    ? translations.notfound.urlDescription 
    : translations.notfound.modalDescription;

  return (
    <div className="container">
      <div className="card notfound-card">
        <h2>{translations.notfound.title} <FontAwesomeIcon icon={faMagnifyingGlass}/></h2>
        <p>{description}</p>
        {source === 'url' && <Link to={"/"}><button className="big-button">{translations.notfound.frontpage}</button></Link>}
      </div>
    </div>
  );
}

export default NotFound;