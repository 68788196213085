import { useLanguage } from '../contexts/LanguageContext';
import { DotLoader } from "react-spinners";

const Loader: React.FC = () => {
  const { translations } = useLanguage();

  return (
    <div className="loader-container">
      <DotLoader color="#00aeff" size={60}/>
      <br></br>
      <p className="loading">{translations.loading}</p>
    </div>
  );
}

export default Loader;