import apiRequest from "../utils/api";

const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const checkUserSubscription = async () => {
  try {
    const serviceWorker = await navigator.serviceWorker.ready;
    const subscription = await serviceWorker.pushManager.getSubscription();

    if (!subscription) {
      return false;
    }

    const endpoint = subscription.endpoint;
    const response = await apiRequest(`/api/subscriptions/check-subscription?endpoint=${encodeURIComponent(endpoint)}`);
    const data = await response.json();
    return data.isSubscribed;
  } catch (error) {
    console.error('Failed to check user subscription:', error);
    return false;
  }
};

const subscribeToPushNotifications = async () => {

  if (!vapidPublicKey) {
    console.error('VAPID public key is missing');
    return;
  }

  try {
    const serviceWorker = await navigator.serviceWorker.ready;

    let subscription = await serviceWorker.pushManager.getSubscription();
    if (!subscription) {
      subscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
      });
    }

    if (subscription) {
      await sendSubscriptionToServer(subscription);
    }
  } catch (error) {
    console.error('Failed to subscribe to push notifications:', error);
  }
};

const sendSubscriptionToServer = async (subscription: PushSubscription) => {
  const subscriptionJson = subscription.toJSON();
  const keys = subscriptionJson.keys;

  if (!keys || !keys.p256dh || !keys.auth) {
    console.error('Subscription keys are missing');
    return;
  }

  try {
    const response = await apiRequest(`/api/subscriptions/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subscription: {
          endpoint: subscription.endpoint,
          keys: {
            p256dh: keys.p256dh,
            auth: keys.auth
          }
        }
      })
    });

    if (!response.ok) {
      console.error('Failed to send subscription to server');
    }
  } catch (error) {
    console.error('Failed to send subscription to server:', error);
  }
};

export { subscribeToPushNotifications, checkUserSubscription };