/**
 * Checks if the input contains only allowed characters.
 * @param input - The input string to validate.
 * @param allowedChars - A regex pattern representing allowed characters.
 * @returns True if the input is valid, otherwise false.
 */
export const isValidInput = (input: string, allowedChars: RegExp): boolean => {
    return allowedChars.test(input);
};

/**
 * Checks if the input length is within the specified limit.
 * @param input - The input string to validate.
 * @param minLength - The minimum allowed length.
 * @param maxLength - The maximum allowed length.
 * @returns True if the input length is valid, otherwise false.
 */
export const isValidLength = (input: string, minLength: number, maxLength: number): boolean => {
    return input.length >= minLength && input.length <= maxLength;
};