import { useState, useRef, useCallback } from 'react';

const usePullToRefresh = (fetchData: () => Promise<void>, swipeThreshold = 100, scrollContainerRef: React.RefObject<HTMLElement>) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [spinnerSize, setSpinnerSize] = useState(0);
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  const isAtTop = useCallback(() => {
    const container = scrollContainerRef.current;
    return container ? container.scrollTop <= 0 : false;
  }, [scrollContainerRef]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isAtTop()) {
      touchStartY.current = e.touches[0].clientY;
      setIsRefreshing(false);
      setSpinnerSize(0); // Reset spinner size on new touch start
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isAtTop()) {
      touchEndY.current = e.touches[0].clientY;
      const swipeDistance = touchEndY.current - touchStartY.current;
      const loadingIndicatorThreshold = 50; // Threshold to start showing the loading indicator

      if (swipeDistance > loadingIndicatorThreshold) {
        setIsRefreshing(true);
        setSpinnerSize(Math.min(swipeDistance - loadingIndicatorThreshold, 100)); // Cap the size at 100
      }
    }
  };

  const handleTouchEnd = () => {
    if (isAtTop()) {
      const swipeDistance = touchEndY.current - touchStartY.current;

      if (swipeDistance > swipeThreshold) {
        // Swipe down - trigger refresh
        fetchData().finally(() => {
          setIsRefreshing(false);
          setSpinnerSize(0); // Reset spinner size after refresh
        });
      } else {
        setIsRefreshing(false); // Reset if swipe distance is not enough
        setSpinnerSize(0); // Reset spinner size
      }
    }
  };

  return {
    isRefreshing,
    spinnerSize,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default usePullToRefresh;